<template>
  <v-navigation-drawer
    v-model="abrirSidebar"
    absolute
    temporary
    style="overflow: hidden"
    width="350">
    <upload-file
      v-if="tipoImportacao"
      name="produtoSidebar-upload"
      :title="$t('title.importar_arquivo')"
      :subtitle="subtitle"
      :id="uploadFileDropzoneId"
      :url="importUrl"
      @UPLOADFILE_SUCCESS="uploadSuccess"
      @UPLOADFILE_ERROR="uploadError"
      @UPLOADFILE_SENDING="enviandoArquivo"
      :accepted-file-types="tipoImportacao.arquivosSuportados"/>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="$vuetify.breakpoint.xs" @click="close">{{ $tc('label.fechar', 1)}}</v-btn>
      </v-row>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { basePath } from '@/common/resources/importacoes';

import UploadFile from '@/shared-components/upload/UploadFile';

export default {
  data() {
    return {
      abrirSidebar: false,
    };
  },
  computed: {
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_produto');
    },
    importUrl() {
      return `${basePath}importacao`;
    },
    subtitle() {
      return this.$t('subtitle.importar_arquivo', {
        thing: this.$tc('label.produto', 1).toLowerCase(),
      });
    },
    uploadFileDropzoneId() {
      return `myVueDropzone${(Math.random() * Math.random() * 100).toString().replace('.', '')}`;
    },
  },
  components: {
    UploadFile,
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('id_tipo_importacao', 1);
    },
    close() {
      this.abrirSidebar = false;
    },
    open() {
      this.abrirSidebar = true;
    },
    toggle() {
      this.$refs.leftSidenav.toggle();
    },
    uploadError(file, msg) {
      this.$toast(msg || this.$t('message.erro_tente_importar_novamente'));
    },
    uploadSuccess() {
      this.$toast(this.$t('message.importado_em_breve_sera_processado'), 'bottom', 8E3);
    },
  },
};
</script>
